
















































import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { SfBreadcrumbs } from '@storefront-ui/vue';

type Crumb = {
  link: string;
  text?: string;
  testId?: string;
};

export default defineComponent({
  name: 'Breadcrumbs',
  components: { SfBreadcrumbs },
  props: {
    crumbs: { type: Array as PropType<Crumb[]>, default: () => [] }
  }
});
