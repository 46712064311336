import { onMounted } from '@nuxtjs/composition-api';
import { getCurrencyCode } from '~/helpers/utils';

export const buildCheckoutProductsList = (productsList = []) =>
  productsList.map((item) => ({
    name: item.name,
    id: item.sku,
    price: item.price / item.quantity,
    quantity: item.quantity,
    brand: item.brand,
    variant: item.variant,
    category: item.category
  }));

const checkoutEvent = ({ step, products }) => ({
  ecommerce: {
    checkout: {
      actionField: {
        step
      }
    },
    products
  },
  event: 'checkout'
});

const addToCartEvent = ({ products, vsfContext }) => ({
  ecommerce: {
    currencyCode: getCurrencyCode({ vsfContext }),
    add: {
      products
    }
  },
  event: 'addToCart'
});

const removeFromCartEvent = ({ products, vsfContext }) => ({
  ecommerce: {
    currencyCode: getCurrencyCode({ vsfContext }),
    remove: {
      products
    }
  },
  event: 'removeFromCart'
});

const userEmailChangedEvent = ({ email }) => ({
  email,
  event: 'user_email_changed'
});

export const buildPurchaseEvent = (checkoutSuccessResult) => {
  const buildProductsList = () =>
    checkoutSuccessResult?.saleslines?.map(
      (item) =>
        ({
          id: item.product_code,
          name: item.description,
          price: item.unit_price,
          brand: item.productbrand,
          quantity: item.quantity,
          variant: item.variant,
          category: item.category
        } || [])
    );
  const buildDeliveryAddress = () => {
    const address = checkoutSuccessResult?.delivery_address || {};
    return {
      street: address.street,
      postalcode: address.postal_code,
      city: address.city,
      country: address.country
    };
  };
  const buildCustomer = () => ({
    first_name: checkoutSuccessResult?.delivery_address?.first_name || null,
    last_name: checkoutSuccessResult?.delivery_address?.surname || null,
    email: checkoutSuccessResult?.saleslines?.[0]?.email || null,
    phone_number: checkoutSuccessResult?.delivery_address?.phone_number || null,
    delivery_address: buildDeliveryAddress()
  });
  const buildActionField = () => ({
    id: checkoutSuccessResult?.number || null,
    affiliation: checkoutSuccessResult?.saleslines?.[0]?.affiliation,
    revenue: checkoutSuccessResult?.saleslines?.[0]?.revenue,
    tax: checkoutSuccessResult?.saleslines?.[0]?.tax,
    shipping: checkoutSuccessResult?.saleslines?.[0]?.shipping ?? null
  });

  return {
    customer: buildCustomer(),
    actionField: buildActionField(),
    products: buildProductsList()
  };
};

const purchaseEvent = ({ customer, actionField, products }) => ({
  customer,
  ecommerce: {
    purchase: {
      actionField,
      products
    }
  },
  event: 'purchase'
});

const cookiesAcceptedEvent = () => ({
  event: 'cookie_accepted'
});

const searchInteractionEvent = ({
  searchTerm,
  interactionType,
  itemId,
  itemName
}) => ({
  event: 'search_interaction',
  search_term: searchTerm,
  interaction_type: interactionType,
  item_id: itemId,
  item_name: itemName
});

const productImpressionEvent = ({
  products,
  skippedProductsCount,
  vsfContext
}) => {
  const formattedProductsList = products.map((product, index) => ({
    id: product.sku,
    name: product.name,
    price: product.price,
    brand: product.brand,
    category: product.category,
    list: 'Product list',
    position: index + 1 + skippedProductsCount
  }));
  const currencyCode = getCurrencyCode({ vsfContext });
  return {
    ecommerce: {
      currencyCode,
      impressions: formattedProductsList
    },
    Exbtw: {
      currencyCode,
      impressions: formattedProductsList.map((product) => ({
        ...product,
        price: product.price,
        list: undefined
      }))
    },
    event: 'productImpression'
  };
};

const EVENTS_MAP = {
  checkout: checkoutEvent,
  addToCart: addToCartEvent,
  removeFromCart: removeFromCartEvent,
  userEmailChanged: userEmailChangedEvent,
  purchase: purchaseEvent,
  cookiesAccepted: cookiesAcceptedEvent,
  searchInteraction: searchInteractionEvent,
  productImpression: productImpressionEvent
};

export const eventBuilder = (eventName) => {
  return EVENTS_MAP[eventName];
};

export default function useGoogleAnalytics({ onMountedEvents } = {}) {
  const registerEvent = (event) => {
    const dataLayer = window.dataLayer;
    if (!dataLayer) return;
    dataLayer.push(event);
  };

  onMounted(() => {
    if (onMountedEvents) {
      onMountedEvents.forEach((event) => registerEvent(event));
    }
  });

  return {
    registerEvent
  };
}
